import { Theme } from '@cutr/constants/cutlist-theme';
import cn from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';

import { useLeadDetails } from '@/api/account';
import { resetErrors } from '@/api/errors';
import { useFeatureFlag } from '@/api/featureFlags';
import { useAuthStore, useIsLoggedIn } from '@/api/login';
import { useMaterialGroupState } from '@/api/materialsGroup';
import { useNestingStore } from '@/api/nesting';
import { usePricingStore } from '@/api/pricing';
import { useCutlistState, useSavingDraftStore } from '@/api/store';
import { Button, RouterButton } from '@/primitives/Button';
import {
  CircledCheckmark,
  Email,
  Icon,
  Loader,
  Login,
  Logout,
  Menu,
  Moon,
  Phone,
  QuestionMark,
  Sun,
} from '@/primitives/Icons';
import {
  useCurrentFeatures,
  useCurrentSource,
  useTheme,
  useThemeConfig,
} from '@/theme';

import { api } from '../api/backend';
import { ConfirmationDialog } from './ConfirmationDialog';
import styles from './Header.module.css';
import { LoginModal } from './LoginModal';
import { Modal } from './Modal';

const ENVIRONMENT = import.meta.env.VITE_CUTR_ENV;
export default function Header() {
  const isLoggedIn = useIsLoggedIn();
  const source = useCurrentSource();
  const config = useThemeConfig();
  const Logo = config.logo;
  const { t } = useTranslation();
  const location = useLocation();

  const { name: owner } = useLeadDetails();
  const { integrationType } = useCurrentFeatures();

  const shouldDisplayLoadingIcon =
    location.pathname.includes('/parts') ||
    location.pathname.includes('/details') ||
    location.pathname.includes('/review') ||
    location.pathname.includes('/done');

  const isCutlistHeader = location.pathname.includes('/cutlist');

  return (
    <>
      <header className={styles.header}>
        <a href={config.logoUrl || config.baseUrl} className="logo">
          <Logo />
        </a>
        {isCutlistHeader && integrationType === 'stand-alone' && owner && (
          <strong style={{ color: 'var(--button-header-color)' }}>
            {t('cutlist-form.owner', { owner })}
          </strong>
        )}
        <div style={{ marginLeft: 'auto' }} className="flexAlign">
          {shouldDisplayLoadingIcon && <LoadingIcon />}

          {ENVIRONMENT !== 'production' && <ModeSwitch />}

          {integrationType === 'stand-alone' && (
            <>
              {isLoggedIn && <OrdersButton />}
              {isLoggedIn && <LogoutBtn />}
              {!isLoggedIn && <LoginBtn />}
              {config.email && <HelpButton />}
            </>
          )}
          <LanguageSelector />
        </div>
      </header>
      {ENVIRONMENT !== 'production' && <NonProductionWarning />}
      {source === 'CUTR' && <DemoWarning />}
    </>
  );
}

const OrdersButton = () => {
  const { t } = useTranslation();

  return (
    <RouterButton
      variant="header"
      data-cy="close-button"
      icon={<Icon icon={<Menu />} />}
      to={'/'}
    >
      {t('common.cta.orders')}
    </RouterButton>
  );
};

const LoadingIcon = () => {
  const { isSaving } = useSavingDraftStore();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const isLoggedIn = useIsLoggedIn();
  const { pathname } = useLocation();

  const shouldBlockNavigation =
    (!isLoggedIn || isSaving) && pathname.includes('/parts');

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const searchParams = new URLSearchParams(nextLocation.search);
    const forceRedirect = searchParams.get('force');

    const shouldBlock =
      shouldBlockNavigation &&
      currentLocation.pathname !== nextLocation.pathname &&
      !forceRedirect;

    if (shouldBlock) {
      setIsOpen(true);
    }

    return shouldBlock;
  });

  React.useEffect(() => {
    if (!shouldBlockNavigation) return;

    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      const target = e.currentTarget as Window;
      if (target?.location?.pathname === '/login') return;
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isSaving, isLoggedIn, isOpen]);

  return (
    <>
      <Icon
        className="tooltip tooltipBottom"
        style={{ color: 'var(--button-header-color)' }}
        data-tooltip={isSaving ? t('common.cta.saving') : t('common.cta.saved')}
        icon={isSaving ? <Loader /> : <CircledCheckmark />}
        size={3}
      />
      {blocker.state === 'blocked' && (
        <ConfirmationDialog
          title={t('cutlist-form.dataMightBeLostDialog.title')}
          onConfirm={() => blocker.proceed()}
          onClose={() => {
            setIsOpen(false);
            blocker.reset();
          }}
          isOpen={isOpen}
          variant="alert"
        >
          {t('cutlist-form.dataMightBeLostDialog.content')}
        </ConfirmationDialog>
      )}
    </>
  );
};

function NonProductionWarning() {
  const { hostname } = useThemeConfig();

  if (!hostname) return null;
  const cutlistUrl = 'https://' + hostname;
  return (
    <WarningStrip
      translationKey="common.header.non-prod-warning"
      url={cutlistUrl}
    />
  );
}

function DemoWarning() {
  const { headerWarningUrl } = useThemeConfig();

  const cutlistUrl = 'https://' + headerWarningUrl;
  return (
    <WarningStrip
      translationKey="common.header.demo-warning"
      url={cutlistUrl}
    />
  );
}

const WarningStrip = ({
  translationKey,
  url,
}: {
  translationKey: string;
  url: string;
}) => {
  return (
    <div className={styles.warning}>
      <div>
        <Trans
          i18nKey={translationKey}
          values={{ url }}
          components={[
            <a key={url} href={url} target="_blank" rel="noreferrer">
              {url}
            </a>,
          ]}
        />
      </div>
    </div>
  );
};

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const currentLng = i18n.resolvedLanguage;

  const languagesForThemes = (theme: Theme) => {
    if (theme === 'swisskrono') return ['en', 'de'];
    if (theme === 'cutr') return ['en', 'de', 'nl', 'ro'];
    if (theme === 'salice') return ['ro'];

    return ['en', 'nl'];
  };

  const languages = languagesForThemes(theme);

  const changeLanguage = async (lng: string) => {
    i18n.changeLanguage(lng);
    await api.updateUserLeadLocale();
  };

  return (
    <div className="flexAlign gap-xxs">
      {languages.length > 1 &&
        languages.map((lng) => {
          const style =
            lng === currentLng
              ? {
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }
              : undefined;
          return (
            <Button
              key={lng}
              variant="iconHeader"
              onClick={async () => await changeLanguage(lng)}
              style={style}
            >
              {t(`common.lng.${lng}`)}
            </Button>
          );
        })}
    </div>
  );
};

// avoids triggering animations while switching theme
function toggleTheme(className: string) {
  const css = document.createElement('style');
  css.appendChild(
    document.createTextNode(
      `* {
        transition: none !important;
      }`
    )
  );
  document.head.appendChild(css);
  document.documentElement.classList.toggle(className);
  window.getComputedStyle(css).opacity;
  document.head.removeChild(css);
}

const ModeSwitch = () => {
  const [hasDM, setDM] = React.useState(
    document.documentElement.classList.contains('darkMode')
  );
  const toggleMode = () => setDM((m) => !m);

  React.useEffect(() => {
    // first execution will toggle the theme
    if (!document.documentElement.classList.contains('darkMode') && !hasDM)
      return;

    toggleTheme('darkMode');
  }, [hasDM]);

  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.altKey && e.code === 'KeyT') {
        toggleMode();
      }
    };
    document.addEventListener('keypress', onKeyPress);

    return () => document.removeEventListener('keypress', onKeyPress);
  }, []);

  return (
    <>
      <Button
        onClick={toggleMode}
        className="tooltip tooltipBottom btnLightMode"
        data-tooltip="Light mode"
        variant="icon"
        style={{ color: 'var(--ctr-primary)' }}
        icon={<Icon icon={<Sun />} />}
      />
      <Button
        onClick={toggleMode}
        variant="icon"
        className="tooltip tooltipBottom btnDarkMode"
        data-tooltip="Dark mode"
        style={{ color: 'var(--button-header-color)' }}
        icon={<Icon icon={<Moon />} />}
      />
    </>
  );
};

const LogoutBtn = () => {
  const { logout, clientNumber } = useAuthStore();
  const { t } = useTranslation();
  const priceStore = usePricingStore();
  const nestingStore = useNestingStore();
  const cutlistState = useCutlistState();
  const groupStore = useMaterialGroupState();
  const navigate = useNavigate();

  const onLogout = () => {
    logout();

    resetErrors();
    priceStore.reset();
    groupStore.clear();
    cutlistState.reset();
    nestingStore.setNesting([]);
    navigate('/');
  };

  return (
    <Button
      className={cn('tooltip', 'tooltipBottom')}
      icon={<Icon icon={<Logout />} />}
      variant="header"
      onClick={onLogout}
      data-tooltip={clientNumber}
    >
      {t('common.logout')}
    </Button>
  );
};

const LoginBtn = () => {
  const { t } = useTranslation();
  const isUnauthUsersEnabled = useFeatureFlag('cutlist-unauth-users');
  const { allowUnauthUsers } = useCurrentFeatures();
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  if (!isUnauthUsersEnabled || !allowUnauthUsers) return null;

  return (
    <>
      <Button
        icon={<Icon icon={<Login />} />}
        className="smaller header"
        style={{
          background: 'var(--header-cta-background)',
          color: 'var(--header-cta-color)',
        }}
        onClick={() => setLoginModalOpen(true)}
      >
        {t('common.login')}
      </Button>
      <LoginModal
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </>
  );
};

// Help button & modal

const HelpButton = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <Button
        variant="header"
        icon={<Icon icon={<QuestionMark />} />}
        onClick={() => setOpen(true)}
      >
        {t('common.help.title')}
      </Button>
      <HelpModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

type HelpModalProps = { setOpen: (isOpen: boolean) => void; isOpen: boolean };
export const HelpModal = ({ setOpen, isOpen }: HelpModalProps) => {
  const { t } = useTranslation();
  const config = useThemeConfig();

  return (
    <Modal hug title={''} handleClose={() => setOpen(false)} isOpen={isOpen}>
      <div className={styles.helpModal}>
        <div className={styles.content}>
          <HowTo />
          <Accordion />
          <div className={styles.contacts}>
            <h3>{t('common.help.contactDetails')}</h3>
            <div className={styles.details}>
              <div className={styles.withIcon}>
                <Icon icon={<Phone />} />
                <div>
                  <strong>{t('common.help.phone')}</strong>
                  <br />
                  <a href={`tel:${config.email}`}>{config.phone}</a>
                </div>
              </div>

              <div className={styles.withIcon}>
                <Icon icon={<Email />} />
                <div>
                  <strong>{t('common.help.email')}</strong>
                  <br />
                  <a href={`mailto:${config.email}`}>{config.email}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Accordion = () => {
  const { t } = useTranslation();

  const data = t(`landing-page.faq`, {
    returnObjects: true,
  }) as {
    id: string;
    title: string;
    body: string;
    hasLink?: string;
  }[];

  if (typeof data !== 'object') return null;

  data.forEach((el, i) => (el.id = 'faq' + i));

  const [isExpanded, setIsExpanded] = React.useState<undefined | string>(
    undefined
  );

  return (
    <div className={styles.faq}>
      <h3>{t('landing-page.titles.faq')}</h3>
      <dl>
        {data.map((content, index) => (
          <React.Fragment key={content.id}>
            <dt>
              <button
                id={`${content.id}-header`}
                aria-controls={`${content.id}-panel`}
                aria-expanded={isExpanded === content.id}
                onClick={() =>
                  setIsExpanded(
                    content.id === isExpanded ? undefined : content.id
                  )
                }
              >
                {content.title}
              </button>
            </dt>
            <dd
              id={`${content.id}-panel`}
              aria-labelledby={`${content.id}-header`}
              hidden={isExpanded !== content.id}
            >
              {content.hasLink ? (
                <Trans
                  i18nKey={`landing-page.faq.` + index + '.body'}
                  components={[
                    <a
                      key={`url+${index}`}
                      href={content.hasLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      URL
                    </a>,
                  ]}
                />
              ) : (
                content.body
              )}
            </dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  );
};

const HowTo = () => {
  const { t } = useTranslation();

  const data = t(`landing-page.howTo`, {
    returnObjects: true,
  }) as {
    id: string;
    title: string;
    body: string;
  }[];

  if (typeof data !== 'object') return null;

  data.forEach((el, i) => (el.id = 'howto' + i));

  return (
    <div className={styles.howTo}>
      <h3>{t('landing-page.titles.howTo')}</h3>
      <ol>
        {data.map((content) => (
          <li key={content.id}>
            <strong>{content.title}</strong>
            <br />
            {content.body}
          </li>
        ))}
      </ol>
    </div>
  );
};
