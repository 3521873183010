import {
  CutlistConfig,
  cutlistConfigs,
  defaultFeatures,
  Theme,
} from '@cutr/constants/cutlist-theme';
import { create } from 'zustand';

import { useMaterialGroupState } from './api/materialsGroup';
import { useCutlistState } from './api/store';
import {
  BNBLogo,
  CutrLogo,
  FinsaLogo,
  SaliceLogo,
  StihoLogo,
  SwissKronoLogo,
  VerdouwLogo,
} from './blocks/Logos';
import { useCurrentFeaturesFromProvider } from './FeatureProvider';
import { SessionStorage } from './utils/storage';

type UiConfig = {
  logo: () => JSX.Element;
  backBtnKey: string;
  backBtnName?: string;
  backBtnUrl: string;
  baseUrl: string;
  logoUrl?: string;
  newAccountUrl?: string;
  seeCustomerNumberUrl?: string;
  favicon: string;
  welcomeImage?: string;
  start: string;
  showCutrLabel?: boolean;
  pageTitleName?: string;
  allowedRoutes?: string[];
};

const themeUiConfigs: Record<Theme, UiConfig> = {
  cutr: {
    logo: CutrLogo,
    backBtnKey: 'common.cta.backHome',
    backBtnUrl: '/',
    baseUrl: '/',
    favicon: 'favicon.ico',
    start: '/',
    newAccountUrl: 'mailto:lukas.eifert@cutr.com',
  },
  cutrembedded: {
    logo: CutrLogo,
    backBtnKey: 'common.cta.backHome',
    backBtnUrl: '/',
    baseUrl: '/',
    favicon: 'favicon.ico',
    start: '/',
  },
  bnb: {
    logo: BNBLogo,
    backBtnKey: 'common.cta.backTo',
    backBtnName: 'Baars & Bloemhoff',
    backBtnUrl: '/home',
    baseUrl: 'https://www.baars-bloemhoff.nl/',
    logoUrl: 'https://www.baars-bloemhoff.nl/assortiment/',
    newAccountUrl: 'https://www.baars-bloemhoff.nl/account',
    seeCustomerNumberUrl: 'https://www.baars-bloemhoff.nl/',
    favicon: 'favicon-bnb.ico',
    start: '/home',
    welcomeImage: '/bnb-landing.png',
    showCutrLabel: false,
    pageTitleName: 'Baars & Bloemhoff',
  },
  stiho: {
    logo: StihoLogo,
    backBtnKey: 'common.cta.backTo',
    backBtnName: 'Stiho',
    backBtnUrl: '/home',
    baseUrl: 'https://www.stiho.nl/',
    logoUrl: 'https://www.stiho.nl/',
    newAccountUrl: 'https://www.stiho.nl/klantworden',
    seeCustomerNumberUrl: 'https://www.stiho.nl/account',
    favicon: 'favicon-stiho.ico',
    start: '/home',
    welcomeImage: '',
    pageTitleName: 'Stiho',
  },
  salice: {
    logo: SaliceLogo,
    backBtnKey: 'common.cta.backTo',
    backBtnName: 'Salice',
    backBtnUrl: '/home',
    baseUrl: 'https://www.salice.ro/',
    logoUrl: 'https://www.salice.ro/',
    newAccountUrl: 'https://salice.ro/customer/account/create/',
    seeCustomerNumberUrl: 'https://www.salice.ro/',
    favicon: 'favicon-salice.ico',
    start: '/home',
    welcomeImage: '',
    pageTitleName: 'Salice',
  },
  verdouw: {
    logo: VerdouwLogo,
    backBtnKey: 'common.cta.backTo',
    backBtnName: 'Verdouw',
    backBtnUrl: ['development', 'staging'].includes(
      import.meta.env.VITE_CUTR_ENV
    )
      ? 'https://canary.verdouw.nu/cart'
      : 'https://www.verdouw.nu/cart',
    baseUrl: 'https://www.verdouw.nu/',
    logoUrl: '#',
    newAccountUrl: 'https://www.verdouw.nu/',
    seeCustomerNumberUrl: 'https://www.verdouw.nu/',
    favicon: 'favicon-verdouw.ico',
    start: '/home',
    welcomeImage: '/verdouw-landing.png',
    pageTitleName: 'Verdouw',
    allowedRoutes: [
      '/cutlist/:id/',
      '/cutlist/:id/parts',
      '/open',
      '/edit/:id',
      '/admin',
      '/admin/orders',
      '/admin/orders/:id',
      '/admin/orders/:id/edit',
    ],
  },
  swisskrono: {
    logo: SwissKronoLogo,
    backBtnKey: 'common.cta.backTo',
    backBtnName: 'Swiss Krono',
    backBtnUrl: '/home',
    baseUrl: 'https://www.swisskrono.com/',
    favicon: 'favicon-swisskrono.ico',
    start: '/home',
    welcomeImage: '',
  },
  finsa: {
    logo: FinsaLogo,
    backBtnKey: 'common.cta.backTo',
    backBtnName: 'Finsa',
    backBtnUrl: '/home',
    baseUrl: 'https://www.finsa.com/',
    favicon: 'favicon-finsa.ico',
    start: '/home',
    welcomeImage: '',
  },
};

const themes = Object.keys(themeUiConfigs) as Theme[];

interface ThemeState {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const urlParams = new URLSearchParams(window.location.search);
const themeFromParam = urlParams.get('t') as Theme;

// order:
// `t` param in URL
// get from session storage
// based on hostname
function getThemeFromPath() {
  const themeOverride = getThemeOverride();
  if (themeOverride) return themeOverride;

  if (import.meta.env.VITE_CUTR_ENV === 'staging') {
    const stagingThemeOverride = getStagingThemeOverride();
    if (stagingThemeOverride) return stagingThemeOverride;
  }

  for (const key of themes) {
    const theme = getThemeConfig(key) || {};

    if (window.location.hostname === theme.hostname) return key;
  }

  return themes[2]; //bnb
}

function getStagingThemeOverride() {
  for (const key of themes) {
    const theme = getThemeConfig(key) || {};

    const currentSubdomain = window.location.hostname.split('.')[0];
    const partnerSubdomain = theme.hostname.split('.')[0];

    if (currentSubdomain === partnerSubdomain) return key;
  }

  throw Error('This staging subdomain does not exist.');
}

function getThemeOverride() {
  if (import.meta.env.VITE_CUTR_ENV === 'production') return;
  if (import.meta.env.VITE_CUTR_ENV === 'staging') return;

  const sessionTheme = SessionStorage.get('theme');
  if (themes.includes(themeFromParam)) {
    // if theme has changed, cleanup
    if (sessionTheme && themeFromParam !== sessionTheme) {
      useCutlistState.getState().reset();
      useMaterialGroupState.getState().clear();

      // reload to start from a fresh state
      window.location.reload();
    }

    SessionStorage.set('theme', themeFromParam);
    return themeFromParam;
  }

  if (themes.includes(sessionTheme)) {
    return sessionTheme as Theme;
  }
}

export const getThemeConfig = (theme: Theme) => ({
  ...cutlistConfigs[theme],
  ...themeUiConfigs[theme],
});

export const useTheme = create<ThemeState>((set) => ({
  theme: getThemeFromPath(),
  setTheme: (theme) => set(() => ({ theme })),
}));

export const useThemeConfig = () => {
  const { theme } = useTheme();

  return getThemeConfig(theme);
};

const currentSourceSelector = (state: ThemeState) => {
  const config = getThemeConfig(state.theme);

  return config.source;
};

export const useCurrentSource = () => useTheme(currentSourceSelector);
export const getCurrentConfig = () => {
  const { theme } = useTheme.getState();
  return getThemeConfig(theme);
};

export const getFeatures = (features: CutlistConfig['features']) => {
  return { ...defaultFeatures, ...(features || {}) };
};
export const useCurrentFeatures = () => {
  const features = useCurrentFeaturesFromProvider();
  return features;
};

export const getCurrentFeatures = () => {
  const config = getCurrentConfig();
  return getFeatures(config.features);
};

export const getFeaturesForTheme = (ticker: Theme) => {
  const config = getThemeConfig(ticker);
  return getFeatures(config.features);
};

export const useIsCutrEmbedded = () => {
  const { theme } = useTheme();
  return theme === 'cutrembedded';
};

export const getIsCutrEmbedded = () => {
  const { theme } = useTheme.getState();
  return theme === 'cutrembedded';
};
