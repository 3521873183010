import { MaterialGroup } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useActiveGroup,
  useActiveGroupGrainDirection,
  useMaterialGroupState,
} from '@/api/materialsGroup';
import { selectActions, useCutlistState } from '@/api/store';
import { EdgesTooltip } from '@/blocks/EdgesTooltip';

import { Errors, PartErrors } from '../Errors';
import { EmptyTableNudge } from '../PartTableCommon/EmptyTableNudge';
import { MaterialGroupActions } from '../PartTableCommon/MaterialGroupActions';
import styles from '../PartTableCommon/styles.module.css';
import { useTableErrors } from '../PartTableCommon/useTableErrors';
import { EdgeProcessingConfiguration } from './EdgeProcessingConfiguration';
import { MaterialGroupHeader } from './MaterialGroupHeader';
import {
  SelectedPartsProvider,
  useSelectedPartsContext,
} from './SelectedPartsContext';
import { TableRow } from './TableRow';

export const AgentQuotingPartTable = () => {
  return (
    <SelectedPartsProvider>
      <div className={styles.orderSection}>
        <MaterialGroupSteps />
      </div>
    </SelectedPartsProvider>
  );
};

const MaterialGroupSteps = () => {
  const groups = useMaterialGroupState((state) => state.groups);
  const activeTab = useMaterialGroupState((state) => state.activeGroup);
  const activeGroup = useActiveGroup();

  return (
    <>
      <form name="parts">
        {groups
          .filter((g) => !activeTab || g.id === activeTab)
          .map((g) => (
            <MaterialGroupComponent key={g.id} group={g} />
          ))}
      </form>

      {/* 3. Configure edge processing */}
      {activeGroup?.type && <EdgeProcessingConfiguration />}
    </>
  );
};

const MaterialGroupComponent = ({ group }: { group: MaterialGroup }) => {
  const activeGroup = useActiveGroup();

  return (
    <section
      key={group.id}
      className={styles.partSection}
      id={`section-${group.id}`}
    >
      {/* 1. Choose a material */}
      <MaterialGroupHeader group={group} />

      {/* 2. Configure parts to cut */}
      {activeGroup?.type && <Table group={group} />}
    </section>
  );
};

const Table = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();

  useTableErrors();

  if (!group?.type && !group?.core1) return null;

  return (
    <>
      <h3>{t('agent.quoteFlow.partsToCut')}</h3>
      <PartErrors />
      <table>
        <Head />
        <Body group={group} />
      </table>

      <EmptyTableNudge group={group} />
      <MaterialGroupActions group={group} />
      <p
        style={{
          color: 'var(--gray-8)',
          fontSize: '0.75rem',
          padding: 'var(--space-xs) 0',
        }}
      >
        {t('cutlist-form.info.dimensions')}
      </p>
      <Errors />
    </>
  );
};

const tableHeaders = [
  { labelKey: 'field.partName.label', id: 'label', width: '80px' },
  { labelKey: 'field.quantity.label' },
  { labelKey: 'field.length.label' },
  { labelKey: 'field.width.label' },
  { labelKey: 'field.cncMinutes.label', id: 'cncMinutes' },
  { labelKey: 'field.edgeProcessing.label', id: 'edgeProcessing', colSpan: 5 },
  { labelKey: 'field.grainDirection.label', id: 'grain' },
  { labelKey: '', width: '50px' },
];

const useHeaders = () => {
  const activeGroup = useActiveGroup();
  const hasGrainDirection = useActiveGroupGrainDirection();

  return tableHeaders.filter(({ id }) => {
    if (id === 'grain') return hasGrainDirection;
    if (id === 'edgeProcessing') return activeGroup?.type !== 'sheets-only';
    if (id === 'cncMinutes') return activeGroup?.type !== 'sheets-only';
    return true;
  });
};

const Head = () => {
  const { t } = useTranslation();
  const hasGrainDirection = useActiveGroupGrainDirection();
  const headers = useHeaders();
  const { selectedParts, disableAll, enableAll } = useSelectedPartsContext();

  const activeGroup = useActiveGroup();
  const parts = useCutlistState((state) => state.parts);
  const activeGroupParts = parts.filter((p) => p.groupId === activeGroup?.id);

  const handleMasterCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      enableAll();
    } else {
      disableAll();
    }
  };

  const headerText = (key?: string) => (key ? t(`cutlist-form.${key}`) : '');
  return (
    <thead>
      <tr>
        {activeGroup?.type !== 'sheets-only' && (
          <th style={{ height: '1px' }}>
            <label htmlFor="masterCheckbox" className={styles.label}>
              <input
                id="masterCheckbox"
                type="checkbox"
                onChange={handleMasterCheckbox}
                checked={selectedParts.length === activeGroupParts.length}
              />
              <span className="visually-hidden">
                {selectedParts.length === activeGroupParts.length
                  ? t('cutlist-form.deselectAllParts')
                  : t('cutlist-form.selectAllParts')}
              </span>
            </label>
          </th>
        )}
        {headers.map((h, i) => (
          <th
            key={h.labelKey || i}
            style={{ minWidth: h.width }}
            colSpan={h.colSpan ?? 1}
          >
            {h.id === 'edgeProcessing' ? (
              <div>
                {headerText(h.labelKey)}
                <EdgesTooltip />
              </div>
            ) : (
              headerText(h.labelKey)
            )}
          </th>
        ))}
      </tr>
      {activeGroup?.type !== 'sheets-only' && (
        <tr>
          <th />
          <th />
          <th />
          <th />
          <th />
          <th />
          <th>{t('cutlist-form.field.edgebanding.sides.l1')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.l2')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.w1')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.w2')}</th>
          <th />
          {hasGrainDirection && <th />}
          <th />
        </tr>
      )}
    </thead>
  );
};

const Body = ({ group }: { group: MaterialGroup }) => {
  const ref = React.useRef(null);
  const shouldFocusLast = React.useRef(false);
  const { addPart } = useCutlistState(selectActions);

  const parts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  React.useEffect(() => {
    if (!ref.current) return;
    const element = ref.current as HTMLElement;

    const onFocusOut = (e: FocusEvent) => {
      if (element.contains(e.relatedTarget as HTMLElement)) return;
      const time = e.timeStamp;
      window.addEventListener(
        'keyup',
        (e) => {
          if (e.key === 'Tab' && e.timeStamp - time < 200) {
            addPart(group);
            shouldFocusLast.current = true;
          }
        },
        { once: true }
      );
    };
    element.addEventListener('focusout', onFocusOut);
    return () => {
      element.removeEventListener('focusout', onFocusOut);
    };
  }, [ref]);

  React.useEffect(() => {
    if (!ref.current) return;
    if (!shouldFocusLast.current) return;

    const element = ref.current as HTMLElement;
    const lastInput = element.querySelector(
      'tr:last-child input:first-of-type'
    ) as HTMLInputElement;
    lastInput?.focus();
    shouldFocusLast.current = false;
  }, [parts, ref, shouldFocusLast]);

  return (
    <tbody ref={ref}>
      {parts.map((part) => {
        return <TableRow part={part} key={part.id} />;
      })}
    </tbody>
  );
};
