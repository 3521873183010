import {
  CSV_COL_EN_MAP,
  CSV_COL_NL_MAP,
  DotCutlistState,
  Edgebanding,
  EdgeProfile,
  MaterialGroup,
  MaterialGroupState,
  PartItem,
} from '@cutr/constants/cutlist';
import { nanoid } from 'nanoid';

import { api } from '@/api/backend';
import i18n from '@/i18n';

import { pick } from './backend';
import { backwardsCompatibleDotCutlistFileContent } from './cutlistState';
import { getMaterial } from './materials';
import { DEFAULT_STORE } from './store';

const CSV_COL_EN = CSV_COL_EN_MAP;
const CSV_COL_NL = CSV_COL_NL_MAP;

export function downloadCutlistCsvTemplate(language?: string) {
  const documentName =
    language == 'nl' ? 'ZaaglijstTemplate.csv' : 'CutlistTemplate.csv';
  const url = `/${documentName}`;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', documentName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function importCsvCutlistWithEdgeband(
  files: FileList,
  language?: string,
  activeGroup?: MaterialGroup
): Promise<DotCutlistState | undefined> {
  const file = files[0];
  if (!file) return Promise.resolve(undefined);

  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target?.result as string;
      if (!data) res(undefined);

      try {
        api
          .parseCutlistCsv(data)
          .then((data) => {
            const partData = Object.values(data as object)[0];
            const json = JSON.parse(partData);
            const columnNames = language == 'nl' ? CSV_COL_NL : CSV_COL_EN;
            if (!activeGroup) return;

            const core1Material = getMaterial(activeGroup.core1);
            const edgebandMaterial = getMaterial(activeGroup.edgeband);
            if (!core1Material) return;

            const parts: PartItem[] = [];
            Object.keys(json).forEach((key) => {
              const currentPart = json[key];
              if (
                !edgebandMaterial &&
                (currentPart[columnNames.edgeLength1] == '1' ||
                  currentPart[columnNames.edgeLength2] == '1' ||
                  currentPart[columnNames.edgeWidth1] == '1' ||
                  currentPart[columnNames.edgeWidth2] == '1')
              ) {
                throw new Error(
                  `${i18n.t('upload-download.csv.invalidEdgebanding')}`
                );
              }
              const grainDirection =
                currentPart[columnNames.grainDirection] == '1'
                  ? 'along'
                  : 'none';
              const edgebanding: Edgebanding = {
                length1:
                  currentPart[columnNames.edgeLength1] == '1'
                    ? edgebandMaterial?.articleCode || null
                    : null,
                length2:
                  currentPart[columnNames.edgeLength2] == '1'
                    ? edgebandMaterial?.articleCode || null
                    : null,
                width1:
                  currentPart[columnNames.edgeWidth1] == '1'
                    ? edgebandMaterial?.articleCode || null
                    : null,
                width2:
                  currentPart[columnNames.edgeWidth2] == '1'
                    ? edgebandMaterial?.articleCode || null
                    : null,
              };
              const part: PartItem = {
                id: nanoid(6),
                groupId: activeGroup.id,
                label: currentPart[columnNames.name],
                quantity: currentPart[columnNames.quantity],
                edgebanding: edgebanding,
                grainDirection: grainDirection,
                createLabel: false,
                widthMM: currentPart[columnNames.width],
                lengthMM: currentPart[columnNames.length],
                thickness: core1Material.thicknessUM,
                core1: activeGroup.core1,
                core2: activeGroup.core2,
                topHpl: activeGroup.topHpl,
                bottomHpl: activeGroup.bottomHpl,
                cncSeconds: 0,
                edgeProfile: {
                  length1: 'none',
                  length2: 'none',
                  width1: 'none',
                  width2: 'none',
                },
                partType: currentPart.partType || 'panel',
                grooves: {},
                roundedEdgeband: {},
              };
              parts.push(part);
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res({ parts: parts } as any);
          })
          .catch((error) => {
            rej(error);
          });
      } catch (error) {
        rej(error);
      }
    };

    reader.readAsText(file);
  });
}

export function importCsvCutlistWithEdgeProfile(
  files: FileList,
  language?: string,
  activeGroup?: MaterialGroup
): Promise<DotCutlistState | undefined> {
  const file = files[0];
  if (!file) return Promise.resolve(undefined);

  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target?.result as string;
      if (!data) res(undefined);

      try {
        api
          .parseCutlistCsv(data)
          .then((data) => {
            const partData = Object.values(data as object)[0];
            const json = JSON.parse(partData);
            const columnNames = language == 'nl' ? CSV_COL_NL : CSV_COL_EN;
            if (!activeGroup) return;

            const core1Material = getMaterial(activeGroup.core1);
            if (!core1Material) return;

            const edgeProfile = activeGroup.edgeProfile;

            const parts: PartItem[] = [];
            Object.keys(json).forEach((key) => {
              const currentPart = json[key];
              if (
                edgeProfile === 'none' &&
                (currentPart[columnNames.edgeLength1] == '1' ||
                  currentPart[columnNames.edgeLength2] == '1' ||
                  currentPart[columnNames.edgeWidth1] == '1' ||
                  currentPart[columnNames.edgeWidth2] == '1')
              ) {
                throw new Error(
                  `${i18n.t('upload-download.csv.invalidEdgeProfiling')}`
                );
              }
              const grainDirection =
                currentPart[columnNames.grainDirection] == '1'
                  ? 'along'
                  : 'none';

              const edgeProfiling: EdgeProfile = {
                length1:
                  currentPart[columnNames.edgeLength1] == '1'
                    ? edgeProfile
                    : 'none',
                length2:
                  currentPart[columnNames.edgeLength2] == '1'
                    ? edgeProfile
                    : 'none',
                width1:
                  currentPart[columnNames.edgeWidth1] == '1'
                    ? edgeProfile
                    : 'none',
                width2:
                  currentPart[columnNames.edgeWidth2] == '1'
                    ? edgeProfile
                    : 'none',
              };
              const part: PartItem = {
                id: nanoid(6),
                groupId: activeGroup.id,
                label: currentPart[columnNames.name],
                quantity: currentPart[columnNames.quantity],
                edgebanding: {
                  length1: null,
                  length2: null,
                  width1: null,
                  width2: null,
                },
                grainDirection: grainDirection,
                createLabel: false,
                widthMM: currentPart[columnNames.width],
                lengthMM: currentPart[columnNames.length],
                thickness: core1Material.thicknessUM,
                core1: activeGroup.core1,
                core2: activeGroup.core2,
                topHpl: activeGroup.topHpl,
                bottomHpl: activeGroup.bottomHpl,
                cncSeconds: 0,
                edgeProfile: edgeProfiling,
                partType: currentPart.partType || 'panel',
                grooves: {},
                roundedEdgeband: {},
              };
              parts.push(part);
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res({ parts: parts } as any);
          })
          .catch((error) => {
            rej(error);
          });
      } catch (error) {
        rej(error);
      }
    };

    reader.readAsText(file);
  });
}

function pickCutlistData(data: DotCutlistState) {
  const keysToPick = Object.keys(DEFAULT_STORE);
  keysToPick.push('groupState');
  return pick(
    data,
    keysToPick.filter((key) => key !== 'orderId')
  ) as DotCutlistState;
}

export function dotCutlist(
  cutlistStore: DotCutlistState,
  groupState?: MaterialGroupState
) {
  if (groupState) {
    cutlistStore.groupState = groupState;
  }
  return pickCutlistData(cutlistStore);
}

export function exportCutlist(dotCutlistState: DotCutlistState) {
  const dataStr =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(dotCutlistState));
  const downloadAnchorNode = document.createElement('a');
  const fileName = dotCutlistState.title || 'order';
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', fileName + '.cutlist');
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function importCutlist(
  files: FileList
): Promise<DotCutlistState | undefined> {
  const file = files[0];
  if (!file) return Promise.resolve(undefined);

  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target?.result as string;

      if (!data) res(undefined);

      try {
        const json = JSON.parse(data);
        if (!validateState(json)) {
          rej(false);
          return;
        }
        const pickedData = backwardsCompatibleDotCutlistFileContent(
          pickCutlistData(json)
        );

        // reset ids
        pickedData.parts.forEach((part: PartItem) => (part.id = nanoid(6)));

        res(pickedData);
      } catch (error) {
        rej(false);
      }
    };

    reader.readAsText(file);
  });
}

function validateState(data: unknown) {
  // making typescript happy
  if (!(data instanceof Object)) return false;
  const dataKeys = Object.keys(data);
  dataKeys.push('customerReference'); // 21/12/22 hotfix to avoid breaking existing cutlist files
  dataKeys.push('orderId'); // We don't emit orderId in the cutlist file
  dataKeys.push('shortId');
  dataKeys.push('requestedDeliveryDate');
  dataKeys.push('deliverLeftoverMaterials');
  dataKeys.push('vatRate');
  dataKeys.push('currency');
  dataKeys.push('sourceCurrency');
  dataKeys.push('exchangeRate');
  dataKeys.push('status');
  dataKeys.push('discountPercentage');
  dataKeys.push('discountAmount');
  dataKeys.push('markupAmount');
  dataKeys.push('markupPercentage');
  dataKeys.push('internalPointOfContact');
  return Object.keys(DEFAULT_STORE).every((key) => dataKeys.includes(key));
}
