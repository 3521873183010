import {
  CUSTOMER_TYPE_OPTIONS,
  CustomerType,
  PRICING_CATEGORY_OPTIONS,
  PricingCategory,
  UserLead,
} from '@cutr/constants/cutlist';
import { useQueryClient } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import { useDeliveryAddress } from '@/api/address';
import { ApiError } from '@/api/backend';
import { useFeatureFlag } from '@/api/featureFlags';
import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { Button } from '@/primitives/Button';
import { Dropdown } from '@/primitives/Dropdown';
import { AddUser, Edit, Icon, UserAvatar } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { SearchInput } from '@/primitives/SearchInput';
import { getSearchOwnerQuery } from '@/queries/agent';
import { useCreateUserLead, useUpdateUserLead } from '@/queries/agent';
import { useAssignCutlistOwner } from '@/queries/crud';
import { agentKeys } from '@/queries/keys';
import { useDebounce } from '@/utils/hooks';

import styles from './CutlistOwnerButton.module.css';
import { Modal } from './Modal';

export const CutlistOwnerButton = ({ readOnly }: { readOnly: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const isNewOwnerButtonEnabled = useFeatureFlag(
    'cutlist-agent-create-user-lead'
  );
  const queryClient = useQueryClient();
  const [hasEdits, setHasEdits] = React.useState(false);

  return (
    <>
      {readOnly ? (
        <OwnerLabel />
      ) : (
        <OwnerButton
          onClick={() => {
            if (hasEdits) {
              queryClient.invalidateQueries({
                queryKey: ['agent', 'search', 'owner'],
              });
            }
            setIsModalOpen(true);
          }}
        />
      )}

      <Modal
        title=""
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        {isNewOwnerButtonEnabled ? (
          <ModalContent
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            hasEdits={hasEdits}
            setHasEdits={setHasEdits}
          />
        ) : (
          <LegacyModalContent setIsModalOpen={setIsModalOpen} />
        )}
      </Modal>
    </>
  );
};

const useAssignOwner = (cutlistId: string, onSuccess: () => void) => {
  const [loading, setLoading] = React.useState(false);
  const { mutateAsync: assignCutlistOwner } = useAssignCutlistOwner(cutlistId);
  const { init } = useLeadDetails();
  const deliveryAddressStore = useDeliveryAddress();
  const { setVatRate } = useCutlistState();
  const { t } = useTranslation();

  const assignOwner = async (email: string) => {
    try {
      setLoading(true);
      const cutlist = await assignCutlistOwner(email);

      const deliveryAddress = cutlist.addresses[0];
      if (deliveryAddress) {
        deliveryAddressStore.init({
          ...deliveryAddress,
          contactName: deliveryAddress.name,
        });
      }

      init({
        ...cutlist.userLeadDetail,
        ...cutlist.userLead,
        notClient: false,
      });

      setVatRate(cutlist.vatRate);
      toast.success(t('agent.setCutlistOwnership.setOwnerSuccess', { email }));
      onSuccess();
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error(t('common.errors.unexpected'));
      }
    } finally {
      setLoading(false);
    }
  };

  return { assignOwner, loading };
};

const OwnerSearch = ({
  onOwnerSelect,
  selectedOwner,
  isModalOpen,
  hasEdits,
}: {
  onOwnerSelect: (owner: UserLead | null) => void;
  selectedOwner?: UserLead | null;
  isModalOpen: boolean;
  hasEdits: boolean;
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500, '');

  const { data } = useQuery<UserLead[]>({
    ...getSearchOwnerQuery(debouncedSearchQuery),
    enabled: debouncedSearchQuery.length >= 3 && isModalOpen,
    gcTime: hasEdits ? 0 : 1000 * 60 * 5,
    staleTime: hasEdits ? 0 : 1000 * 60 * 1,
    queryKey: agentKeys.searchOwner(
      debouncedSearchQuery,
      isModalOpen,
      hasEdits
    ),
  });

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    if (!value) {
      onOwnerSelect(null);
    }
  };

  if (!isModalOpen && searchQuery !== '') {
    setSearchQuery('');
  }

  const handleSelect = (value: string) => {
    const owner = data?.find((userLead: UserLead) => userLead.id === value);
    if (owner) {
      onOwnerSelect(owner);
      setSearchQuery(owner.email ?? '');
    } else {
      onOwnerSelect(null);
    }
  };

  const getOwnerLabel = (userLead: UserLead) => {
    const defaultAddress = userLead.cutlistAddresses?.[0];
    return [
      userLead.email,
      userLead.companyName,
      userLead.clientNumber,
      defaultAddress?.city,
      defaultAddress?.postalCode,
      defaultAddress?.line1,
    ]
      .filter(Boolean)
      .join(', ');
  };

  const options = React.useMemo(() => {
    return (data || []).map((userLead) => ({
      value: userLead.id,
      component: () => <div>{getOwnerLabel(userLead)}</div>,
    }));
  }, [data]);

  return (
    <>
      <SearchInput
        id="owner-search"
        value={searchQuery}
        onValueChange={handleSearchChange}
        onSelectionChange={handleSelect}
        options={options}
        required
      />

      {selectedOwner && (
        <>
          <p>{getOwnerLabel(selectedOwner)}</p>
          <input type="hidden" name="email" value={selectedOwner.email} />
        </>
      )}
    </>
  );
};

const ModalContent = ({
  setIsModalOpen,
  isModalOpen,
  hasEdits,
  setHasEdits,
}: {
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  hasEdits: boolean;
  setHasEdits: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const queryClient = useQueryClient();
  const { id: cutlistId } = useParams<{ id: string }>();
  const { assignOwner, loading } = useAssignOwner(cutlistId as string, () =>
    setIsModalOpen(false)
  );
  const { t } = useTranslation();
  const [selectedOwner, setSelectedOwner] = React.useState<UserLead | null>(
    () => (isModalOpen ? null : null)
  );
  const [showCustomerForm, setShowCustomerForm] = React.useState(() =>
    isModalOpen ? false : false
  );
  const [isEditing, setIsEditing] = React.useState(() =>
    isModalOpen ? false : false
  );

  const handleCustomerSuccess = async (owner: UserLead) => {
    setHasEdits(true);
    await queryClient.invalidateQueries({
      queryKey: ['agent', 'search', 'owner'],
    });
    await assignOwner(owner.email);
    setIsModalOpen(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    try {
      const data = new FormData(e.currentTarget);
      const email = data.get('email');
      await assignOwner(email as string);
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error(t('common.errors.unexpected'));
      }
    }
  };

  if (showCustomerForm) {
    return (
      <CreateOrUpdateCustomerForm
        onCancel={() => {
          setShowCustomerForm(false);
          setIsEditing(false);
        }}
        onSuccess={handleCustomerSuccess}
        initialData={isEditing ? selectedOwner : undefined}
        isEditing={isEditing}
      />
    );
  }

  return (
    <div className={styles.modalContent}>
      <div>
        <h2>{t('agent.setCutlistOwnership.setCutlistOwner')}</h2>
        <p>{t('agent.setCutlistOwnership.setOwnerInstructions')}</p>
      </div>
      <form
        className={styles.formContainer}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        {!selectedOwner ? (
          <div>
            <label htmlFor="email">
              {t(`agent.setCutlistOwnership.email`)} {'*'}
            </label>
            <div>{t('agent.setCutlistOwnership.searchInstructions')}</div>
            <p />
            <OwnerSearch
              onOwnerSelect={setSelectedOwner}
              selectedOwner={selectedOwner}
              isModalOpen={isModalOpen}
              hasEdits={hasEdits}
            />
          </div>
        ) : (
          <Card>
            <div className={styles.cardHeader}>
              <h3>{selectedOwner.companyName || selectedOwner.email}</h3>
              <Button
                variant="secondaryPill"
                onClick={() => {
                  setIsEditing(true);
                  setShowCustomerForm(true);
                }}
                style={{ padding: '4px' }}
                icon={<Icon icon={<Edit />} />}
              />
            </div>
            <div className={styles.cardContent}>
              <p>{selectedOwner.email}</p>
              {selectedOwner.cutlistAddresses?.[0] && (
                <p>
                  {[
                    selectedOwner.cutlistAddresses[0].line1,
                    selectedOwner.cutlistAddresses[0].city,
                    selectedOwner.cutlistAddresses[0].postalCode,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </p>
              )}
              {selectedOwner && <p>{selectedOwner.clientNumber}</p>}
              {selectedOwner && (
                <p>
                  {[selectedOwner.pricingCategory, selectedOwner.customerType]
                    .filter(Boolean)
                    .join(', ')}
                </p>
              )}
            </div>
            <div className={styles.cardFooter}>
              <Button type="submit" disabled={loading}>
                {t('agent.setCutlistOwnership.setOwner')}
              </Button>
            </div>
            <input type="hidden" name="email" value={selectedOwner.email} />
          </Card>
        )}

        <Button
          variant="secondaryPill"
          type="button"
          disabled={loading}
          onClick={() => setShowCustomerForm(true)}
        >
          {t('agent.setCutlistOwnership.createNewCustomer')}
        </Button>
      </form>
    </div>
  );
};

const LegacyModalContent = ({
  setIsModalOpen,
}: {
  setIsModalOpen: (isOpen: boolean) => void;
}) => {
  const { id } = useParams();
  const { assignOwner, loading } = useAssignOwner(id as string, () =>
    setIsModalOpen(false)
  );
  const { t } = useTranslation();
  const [selectedOwner, setSelectedOwner] = React.useState<UserLead | null>(
    null
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    try {
      const data = new FormData(e.currentTarget);
      const email = data.get('email');
      await assignOwner(email as string);
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error(t('common.errors.unexpected'));
      }
    }
  };

  return (
    <div className={styles.modalContent}>
      <div>
        <h2>{t('agent.setCutlistOwnership.setCutlistOwner')}</h2>
        <p>{t('agent.setCutlistOwnership.setOwnerInstructions')}</p>
      </div>
      <form
        className={styles.formContainer}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <div>
          <label htmlFor="email">
            {t(`agent.setCutlistOwnership.email`)} {'*'}
          </label>
          <div>{t('agent.setCutlistOwnership.searchInstructions')}</div>
          <p />
          <OwnerSearch
            onOwnerSelect={setSelectedOwner}
            selectedOwner={selectedOwner}
            isModalOpen={true}
            hasEdits={false}
          />
        </div>

        <Button type="submit" disabled={loading}>
          {t('agent.setCutlistOwnership.setOwner')}
        </Button>
      </form>
    </div>
  );
};

interface CreateOrUpdateCustomerFormProps {
  onCancel: () => void;
  onSuccess: (owner: UserLead) => void;
  initialData?: UserLead | undefined | null;
  isEditing?: boolean;
}

const CreateOrUpdateCustomerForm = ({
  onSuccess,
  initialData,
  isEditing,
}: CreateOrUpdateCustomerFormProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const createUserLead = useCreateUserLead();
  const updateUserLead = useUpdateUserLead();
  const { id: cutlistId } = useParams<{ id: string }>();
  const [formData, setFormData] = React.useState<{
    country: string;
    pricingCategory: PricingCategory;
    customerType: CustomerType;
  }>({
    country: initialData?.cutlistAddresses?.[0]?.country || 'DE',
    pricingCategory:
      initialData?.pricingCategory || PRICING_CATEGORY_OPTIONS[0],
    customerType: initialData?.customerType || CUSTOMER_TYPE_OPTIONS[0],
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formElement = e.target as HTMLFormElement;
      const formFields = new FormData(formElement);

      const data = {
        email: formFields.get('email') as string,
        companyName: formFields.get('companyName') as string,
        clientNumber: formFields.get('clientNumber') as string,
        pricingCategory: formData.pricingCategory,
        customerType: formData.customerType,
        address: {
          name: formFields.get('companyName') as string,
          line1: formFields.get('line1') as string,
          city: formFields.get('city') as string,
          postalCode: formFields.get('postalCode') as string,
          country: formData.country,
        },
      };

      let result;
      if (isEditing && initialData) {
        result = await updateUserLead.mutateAsync({
          id: initialData.id,
          cutlistId: cutlistId!,
          ...data,
        });
      } else {
        result = await createUserLead.mutateAsync(data);
      }

      onSuccess(result);
    } catch (error) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error(t('common.errors.unexpected'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalContent}>
      <div>
        <h2>
          {isEditing
            ? t('agent.setCutlistOwnership.editCustomer')
            : t('agent.setCutlistOwnership.createNewCustomer')}
        </h2>
      </div>
      <form className={styles.formContainer} onSubmit={onSubmit}>
        <div>
          <h4 className={styles.sectionHeader}>
            {t('agent.setCutlistOwnership.createCustomerForm.customer')}
          </h4>
          <label htmlFor="clientNumber">
            {t('agent.setCutlistOwnership.createCustomerForm.clientNumber')}
          </label>
          <Input
            type="text"
            id="clientNumber"
            name="clientNumber"
            required
            defaultValue={initialData?.clientNumber}
          />
          <h4 className={styles.sectionHeader}>
            {t('agent.setCutlistOwnership.createCustomerForm.companyDetails')}
          </h4>
          <label htmlFor="companyName">
            {t('agent.setCutlistOwnership.createCustomerForm.companyName')}
          </label>
          <Input
            type="text"
            id="companyName"
            name="companyName"
            required
            defaultValue={initialData?.companyName}
          />
          <label htmlFor="email">
            {t('agent.setCutlistOwnership.createCustomerForm.email')}
          </label>
          <Input
            type="email"
            id="email"
            name="email"
            required
            defaultValue={initialData?.email}
          />
          <label htmlFor="line1">
            {t('agent.setCutlistOwnership.createCustomerForm.line1')}
          </label>
          <Input
            type="text"
            id="line1"
            name="line1"
            required
            defaultValue={initialData?.cutlistAddresses?.[0]?.line1}
          />

          <div className={styles.inputRow}>
            <div className={styles.inputColumn}>
              <label htmlFor="city">
                {t('agent.setCutlistOwnership.createCustomerForm.city')}
              </label>
              <Input
                type="text"
                id="city"
                name="city"
                required
                defaultValue={initialData?.cutlistAddresses?.[0]?.city}
              />
            </div>
            <div className={styles.inputColumn}>
              <label htmlFor="postalCode">
                {t('agent.setCutlistOwnership.createCustomerForm.postalCode')}
              </label>
              <Input
                type="text"
                id="postalCode"
                name="postalCode"
                required
                defaultValue={initialData?.cutlistAddresses?.[0]?.postalCode}
              />
            </div>
          </div>

          <label htmlFor="country">
            {t('agent.setCutlistOwnership.createCustomerForm.country')}
          </label>
          <div className={styles.dropdownContainer}>
            <Dropdown
              id="country"
              value={formData.country}
              className={styles.dropdown}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, country: value }))
              }
              options={[
                {
                  value: 'DE',
                  component: () => (
                    <div>
                      {t(
                        'agent.setCutlistOwnership.createCustomerForm.Germany'
                      )}
                    </div>
                  ),
                },
                {
                  value: 'CH',
                  component: () => (
                    <div>
                      {t(
                        'agent.setCutlistOwnership.createCustomerForm.Switzerland'
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </div>

          <h4 className={styles.sectionHeader}>
            {t('agent.setCutlistOwnership.createCustomerForm.businessSettings')}
          </h4>
          <div className={styles.inputRow}>
            <div className={styles.inputColumn}>
              <label htmlFor="pricingCategory">
                {t(
                  'agent.setCutlistOwnership.createCustomerForm.pricingCategory'
                )}
              </label>
              <Dropdown
                id="pricingCategory"
                value={formData.pricingCategory}
                className={styles.dropdown}
                onChange={(value: string) =>
                  setFormData((prev) => ({
                    ...prev,
                    pricingCategory: value as PricingCategory,
                  }))
                }
                options={PRICING_CATEGORY_OPTIONS.map((category) => ({
                  value: category,
                  component: () => <div>{category}</div>,
                }))}
              />
            </div>
            <div className={styles.inputColumn}>
              <label htmlFor="customerType">
                {t('agent.setCutlistOwnership.createCustomerForm.customerType')}
              </label>
              <Dropdown
                id="customerType"
                value={formData.customerType}
                className={styles.dropdown}
                onChange={(value: string) =>
                  setFormData((prev) => ({
                    ...prev,
                    customerType: value as CustomerType,
                  }))
                }
                options={CUSTOMER_TYPE_OPTIONS.map((type) => ({
                  value: type,
                  component: () => <div>{type}</div>,
                }))}
              />
            </div>
          </div>
        </div>

        <div>
          <Button type="submit" disabled={loading}>
            {t('agent.setCutlistOwnership.saveAndSetAsOwner')}
          </Button>
        </div>
      </form>
    </div>
  );
};

const OwnerButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { t } = useTranslation();
  const { email } = useLeadDetails();

  return (
    <Button
      className="pill"
      variant="header"
      style={{
        background: 'var(--header-cta-background)',
        color: 'var(--header-cta-color)',
        minWidth: 'fit-content',
        pointerEvents: 'auto',
      }}
      icon={
        email ? <Icon icon={<UserAvatar />} /> : <Icon icon={<AddUser />} />
      }
      onClick={onClick}
    >
      <strong>
        {email
          ? t('cutlist-form.owner', { owner: email })
          : t('agent.setCutlistOwnership.setCutlistOwner')}
      </strong>
    </Button>
  );
};

const OwnerLabel = () => {
  const { t } = useTranslation();
  const { email } = useLeadDetails();

  return (
    <div className="flexAlign gap-xs">
      <Icon icon={<UserAvatar />} />
      <strong>{t('cutlist-form.owner', { owner: email })}</strong>
    </div>
  );
};
