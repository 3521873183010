import cn from 'classnames';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveGroupGrainDirection } from '@/api/materialsGroup';
import { onFocusSelect } from '@/hooks';
import { Icon, Menu } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { cleanNumberInput } from '@/utils/input';

import { GrainButton } from '../PartTableCommon/Components';
import styles from '../PartTableCommon/styles.module.css';
import { TableRowActions } from '../PartTableCommon/TableRowActions';
import { TableRowProps } from '../PartTableCommon/types';
import { EdgeProcessing } from './EdgeProcessing';
import { useSelectedPartsContext } from './SelectedPartsContext';
import { TimeInput } from './TimeInput';

export const TableRowStrip = forwardRef<HTMLTableRowElement, TableRowProps>(
  function TableRowStrip(
    {
      part,
      hasError,
      onPartUpdate,
      onPartDelete,
      isFieldValid,
      validateNumericInput,
    }: TableRowProps,
    ref
  ) {
    const { t } = useTranslation();
    const hasGrainDirection = useActiveGroupGrainDirection();
    const { selectedParts, togglePartSelection } = useSelectedPartsContext();
    return (
      <tr
        ref={ref}
        id={`stripRow-${part.id}`}
        className={hasError ? styles.errors : undefined}
      >
        <td style={{ padding: '0', height: '1px' }}>
          <label
            htmlFor={`panelRow-${part.id}-checkbox`}
            className={styles.label}
          >
            <input
              id={`panelRow-${part.id}-checkbox`}
              type="checkbox"
              onChange={() => togglePartSelection(part.id)}
              checked={selectedParts.includes(part.id)}
            />
            <span className="visually-hidden">
              {selectedParts.includes(part.id)
                ? t('cutlist-form.deselectPart')
                : t('cutlist-form.selectPart')}
            </span>
          </label>
        </td>
        <td>
          <span className="flexAlign gap-xs">
            <Icon
              className={cn(styles.unflex, 'tooltip')}
              data-tooltip={t('cutlist-form.strip')}
              icon={<Menu />}
              color="var(--gray-7)"
            />
            <Input
              className="snug"
              defaultValue={part.label}
              placeholder={t('cutlist-form.field.partName.placeholder')}
              name={`label_${part.id}`}
              key={`label_${part.id}`}
              onFocus={onFocusSelect}
              autoComplete="off"
              data-1p-ignore
              onBlur={(e) => {
                onPartUpdate('label', e.currentTarget.value);
              }}
            />
          </span>
        </td>
        <td>
          <Input
            className={isFieldValid('quantity') ? 'snug' : 'snug invalid'}
            type="number"
            value={part.quantity}
            name={`quantity_${part.id}`}
            key={`quantity_${part.id}`}
            onInput={(e) => {
              onPartUpdate(
                'quantity',
                validateNumericInput('quantity', e.currentTarget.value)
              );
            }}
          />
        </td>
        <td>
          <Input
            className="snug"
            value={part.lengthMM}
            placeholder={t('cutlist-form.field.length.placeholder')}
            pattern="[0-9]+"
            name={`length_${part.id}`}
            key={`length_${part.id}`}
            onInput={(e) => {
              onPartUpdate('lengthMM', cleanNumberInput(e.currentTarget.value));
            }}
          />
        </td>
        <td>
          <Input
            className="snug"
            value={part.widthMM}
            placeholder={t('cutlist-form.field.width.placeholder')}
            pattern="[0-9]+"
            name={`width_${part.id}`}
            key={`width_${part.id}`}
            onInput={(e) => {
              onPartUpdate('widthMM', cleanNumberInput(e.currentTarget.value));
            }}
          />
        </td>
        <td>
          <TimeInput
            value={part.cncSeconds}
            onChange={(value) => onPartUpdate('cncSeconds', value)}
            placeholder={t('cutlist-form.field.cncMinutes.placeholder')}
            data-cy="part-cncSeconds"
            key={`cncSeconds_${part.id}`}
          />
        </td>
        <EdgeProcessing part={part} />
        {hasGrainDirection && (
          <td className={styles.grain}>
            <GrainButton
              disabled={true}
              direction={part.grainDirection}
              onUpdate={(value) => {
                onPartUpdate('grainDirection', value);
              }}
            />
          </td>
        )}
        <td style={{ '--spaceX': '0.1rem' } as React.CSSProperties}>
          <TableRowActions part={part} onPartDelete={onPartDelete} />
        </td>
      </tr>
    );
  }
);
