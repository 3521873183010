import {
  Material,
  MaterialGroup,
  SheetSizeSelectionOption,
} from '@cutr/constants/cutlist';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { groupSearchResultsByVariationGroupAndThickness } from '@/api/materials';
import { MaterialComponent, useMatchingMaterials } from '@/api/materialsGroup';
import Card from '@/blocks/Card';
import { MaterialRow } from '@/blocks/MaterialRow';
import {
  MaterialSelectedProps,
  MaterialVariationRow,
} from '@/blocks/MaterialVariationRow';
import { Modal } from '@/blocks/Modal';
import TabNav from '@/blocks/TabNav';
import { Icon, Star } from '@/primitives/Icons';
import { useCurrentFeatures, useCurrentSource } from '@/theme';

import styles from '../PartTableCommon/styles.module.css';
import { Autocomplete } from './Autocomplete';
import moduleStyles from './SelectMaterialModal.module.css';
import { MaterialUpdate } from './types';

const ModalPrompt = ({ children }: { children: string | React.ReactNode }) => {
  return (
    <div className={styles.modalPrompt}>
      <div className={styles.content}>{children}</div>

      <div className={styles.background}></div>
    </div>
  );
};

const AutomaticSheetSizeSearchResults = (props: {
  group: MaterialGroup;
  variationGroupResults: Material[][];
  onMaterialSelected: (props: MaterialSelectedProps) => void;
}) => {
  return (
    <>
      {props.variationGroupResults.map((materials) => (
        <MaterialVariationRow
          role="option"
          tabIndex={-1}
          aria-selected="false"
          className="materialRow"
          items={materials}
          group={props.group}
          key={materials[0].id}
          onMaterialSelected={props.onMaterialSelected}
        />
      ))}
    </>
  );
};

const ManualSheetSizeSearchResults = (props: {
  materialResults: Material[];
  onMaterialSelected: (props: MaterialSelectedProps) => void;
}) => {
  return (
    <>
      {props.materialResults.map((m) => (
        <MaterialRow
          role="option"
          tabIndex={-1}
          aria-selected="false"
          className="materialRow"
          item={m}
          key={m.id}
          onSelected={(selectedMaterial) =>
            props.onMaterialSelected({
              selectedMaterial,
              automaticSheetSizeMaterials: [],
              sheetSizeSelection: 'manual',
            })
          }
        />
      ))}
    </>
  );
};

interface SearchTabProps {
  id: string;
  field: MaterialComponent;
  item: Material['articleCode'];
  group: MaterialGroup;
  materialResults: Material[];
  materialType: 'hpl' | 'edgeband' | 'core';
  onMaterialResults: (results: Material[]) => void;
  onMaterialSelected: (props: MaterialSelectedProps) => void;
}

const SearchTab = ({
  id,
  field,
  item,
  group,
  materialResults,
  materialType,
  onMaterialSelected,
  onMaterialResults,
}: SearchTabProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0); // the index of the tab
  const { automaticSheetSizeEnabled } = useCurrentFeatures();

  const shouldShowMatchingMaterials = Boolean(
    materialResults.length <= 3 && materialType === 'edgeband'
  );
  const matchingMaterials = useMatchingMaterials();

  const sheetSizeSelectionTabKeys: SheetSizeSelectionOption[] =
    automaticSheetSizeEnabled && field !== 'edgeband'
      ? ['automatic', 'manual']
      : ['manual'];

  const sheetSizeSelectionTabs: string[] = sheetSizeSelectionTabKeys.map(
    (key) => t(`cutlist-form.selectMaterialModal.sheetSizeSelection.${key}`)
  );

  const variationGroupResults =
    groupSearchResultsByVariationGroupAndThickness(materialResults);

  const resultCount =
    sheetSizeSelectionTabKeys[activeTab] === 'automatic'
      ? variationGroupResults.length
      : materialResults.length;

  return (
    <Card>
      <div className="stack">
        <Autocomplete
          placeholder={t('cutlist-form.selectMaterialModal.placeholder')}
          id={id}
          name={field}
          materialType={materialType}
          materialSandwichType={group.materialSandwichType || 'single-core'}
          value={item}
          aria-label={t(`cutlist-form.field.${field}.label`)}
          onResults={onMaterialResults}
        />

        {sheetSizeSelectionTabs.length > 1 && (
          <TabNav
            className={moduleStyles.sheetSizeSelectionTabNav}
            tabs={sheetSizeSelectionTabs}
            activeTab={activeTab}
            onChangeTab={setActiveTab}
          />
        )}
        {materialResults.length ? (
          <p className={cn([styles.resultsCount, 'flexAlign', 'alignEnd'])}>
            {t(`cutlist-form.materialCount`, { count: resultCount })}
          </p>
        ) : null}
        <ModalPrompt>
          {(materialResults.length || matchingMaterials.length) > 0 ? (
            <div className={styles.materialList}>
              {shouldShowMatchingMaterials && (
                <MatchingEdgebandList
                  matchingMaterials={matchingMaterials}
                  onMaterialSelected={(selectedMaterial) =>
                    onMaterialSelected({
                      selectedMaterial,
                      automaticSheetSizeMaterials: [],
                      sheetSizeSelection: 'manual',
                    })
                  }
                />
              )}
              {sheetSizeSelectionTabKeys[activeTab] === 'automatic' ? (
                <AutomaticSheetSizeSearchResults
                  group={group}
                  variationGroupResults={variationGroupResults}
                  onMaterialSelected={onMaterialSelected}
                />
              ) : (
                <ManualSheetSizeSearchResults
                  materialResults={materialResults}
                  onMaterialSelected={onMaterialSelected}
                />
              )}
            </div>
          ) : (
            <p>{t('cutlist-form.selectMaterialModal.start')}</p>
          )}
        </ModalPrompt>
      </div>
    </Card>
  );
};

const MatchingEdgebandList = (props: {
  matchingMaterials: Material[];
  onMaterialSelected: (selected: string) => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {Boolean(props.matchingMaterials.length) && (
        <>
          <h4
            className={styles.dropdownTitle}
            style={{ color: 'var(--calendulaGold)' }}
          >
            <Icon icon={<Star />} size={2} />
            <span>{t('cutlist-form.matchingEdgeband.title')}</span>
          </h4>

          {props.matchingMaterials.map((m) => (
            <MaterialRow
              role="option"
              tabIndex={-1}
              aria-selected="false"
              className="materialRow"
              item={m}
              key={m.id}
              onSelected={props.onMaterialSelected}
            />
          ))}
        </>
      )}
    </>
  );
};

interface SelectMaterialModalProps {
  prompt?: string | React.ReactNode;
  field: MaterialComponent;
  group: MaterialGroup;
  isOpen: boolean;
  handleClose: () => void;
  onUpdateMaterial: MaterialUpdate;
}

const SelectMaterialModal = ({
  field,
  group,
  isOpen,
  handleClose,
  onUpdateMaterial,
}: SelectMaterialModalProps) => {
  const { t } = useTranslation();
  const id = `${field}_${group.id}`;
  const itemArticleCode = group[field] as Material['articleCode'];
  const [materialResults, setMaterialResults] = React.useState<Material[]>([]);
  const source = useCurrentSource();

  const materialType =
    field === 'core1' ? 'core' : field === 'edgeband' ? 'edgeband' : 'hpl';

  const onMaterialSelected = (props: MaterialSelectedProps) => {
    const inputElement = document.getElementById(
      'autocomplete-input-' + id
    ) as HTMLInputElement;
    const searchInput = inputElement?.value;
    const material = materialResults.find(
      (m) => m.articleCode === props.selectedMaterial
    );
    window.analytics.track('Selected material', {
      field: materialType,
      articleCode: props.selectedMaterial,
      supplierCode: material?.supplierCode,
      name: material?.name,
      searchInput,
      success: true,
      ticker: source,
      resultsCount: materialResults.length,
    });
    onUpdateMaterial({
      [field]: props.selectedMaterial,
      sheetSizeSelection: props.sheetSizeSelection,
      automaticSheetSizeMaterials: props.automaticSheetSizeMaterials,
    });
  };

  return (
    <Modal
      title={t('cutlist-form.selectMaterialModal.title')}
      isOpen={isOpen}
      classes="stack"
      handleClose={handleClose}
    >
      <SearchTab
        id={id}
        field={field}
        group={group}
        item={itemArticleCode}
        materialResults={materialResults}
        materialType={materialType}
        onMaterialSelected={onMaterialSelected}
        onMaterialResults={(results) => setMaterialResults(results)}
      />
    </Modal>
  );
};

export default SelectMaterialModal;
